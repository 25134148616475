var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c("div", [
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _vm._m(0),
                      _vm._l(_vm.questionnaires, function (questionnaire) {
                        return _c(
                          "div",
                          { key: questionnaire.id, staticClass: "block" },
                          [
                            _vm._v(
                              " " + _vm._s(questionnaire.sectionIdentifierName)
                            ),
                            _c("br"),
                            _vm._v(" " + _vm._s(questionnaire.userName)),
                            _c("br"),
                            _vm._v(" " + _vm._s(questionnaire.createdAt)),
                            _c("br"),
                            _vm._v(" " + _vm._s(questionnaire.oldResponseData)),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(questionnaire.newResponseData) + " "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Questionnaire History"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }